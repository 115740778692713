import {
    AdditionalWidgetProperties,
    useRefinementList,
    UseRefinementListProps,
} from 'react-instantsearch';

const useOptimizedRefinementList = (
    props: UseRefinementListProps,
    additionalWidgetProperties?: AdditionalWidgetProperties,
) => {
    const limit = 5;
    const showMoreLimit = 1000;
    const { items, refine, ...rest } = useRefinementList(
        {
            escapeFacetValues: false,
            limit,
            showMoreLimit,
            ...props,
        },
        additionalWidgetProperties,
    );

    return {
        ...rest,
        items,
        refine,
        limit,
    };
};
export default useOptimizedRefinementList;
