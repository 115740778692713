import styled from '@emotion/styled';
import ImercoLogoIcon from '~/icons/logo-icon.svg';
import Checkmark from '~/icons/checkmark.svg';
import { Flex } from '~/shared/components/Flex';

type CheckboxProps = {
    isRefined: boolean;
};

export const StyledContainer = styled.div(({ theme }) => ({
    marginBottom: `-${theme.space[2]}`,
    padding: `0 ${theme.space[4]}`,
}));

export const StyledList = styled(Flex)(({ theme }) => ({
    width: `calc(100% + ${theme.space[2]})`,
}));

export const StyledListTextWrapper = styled.div(() => ({
    hyphens: 'auto',
    wordBreak: 'break-word',
    textAlign: 'left',
}));

export const StyledListValueWrapper = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
}));

export const StyledCheckmarkContainer = styled.div<CheckboxProps>(({ theme, isRefined }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.black}`,
    minWidth: '18px',
    height: '18px',
    backgroundColor: isRefined ? theme.colors.black : theme.colors.white,
    borderRadius: theme.space[1],
}));

export const StyledCheckmark = styled(Checkmark)(({ isRefined, theme }) => ({
    height: '12px !important',
    width: '12px !important',
    alignSelf: 'center',
    color: isRefined ? theme.colors.white : theme.colors.black,
}));

export const StyledListTextContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
}));

export const StyledListItem = styled.span<{ isRefined: boolean }>(({ theme, isRefined }) => ({
    display: 'flex',
    gap: theme.space[3],
    alignItems: 'center',
    marginRight: theme.space[2],
    marginBottom: theme.space[2],
    padding: `${theme.space[2]} ${theme.space[3]}`,
    borderRadius: theme.sizes.cornerRadius,
    cursor: 'pointer',
    justifyContent: 'space-between',
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.lineHeights.base,
    background: isRefined ? theme.colors.brownLight : theme.colors.white,
}));

export const StyleListInput = styled.input<{ isEmpty: boolean }>(({ theme, isEmpty }) => ({
    ...theme.mixins.useTextStyle('bodySmall'),
    marginBottom: isEmpty ? 0 : theme.space[3],
    width: '100%',
    padding: theme.space[3],
    border: `1px solid ${theme.colors.black}`,
    borderRadius: theme.sizes.cornerRadius,

    '::placeholder': {
        color: theme.colors.grey50,
    },
}));

export const StyledOnlineToggle = styled.div<{ isRefined: boolean }>(({ theme, isRefined }) => ({
    padding: `${theme.space[3]} ${theme.space[4]}`,
    borderTop: `1px solid ${theme.colors.brownLight}`,
    borderBottom: `1px solid ${theme.colors.brownLight}`,
    background: isRefined ? theme.colors.brownLight : theme.colors.white,

    '&:not(:last-child)': {
        marginBottom: theme.space[3],
    },
}));

export const StyledListItemLogo = styled(ImercoLogoIcon)(({ theme }) => ({
    width: 16,
    height: 16,
    marginRight: theme.space[2],
}));
